import React from 'react'

const Footer = () => {
    return (
        <div>
            <footer>
                <div className="upper-footer">
                    <div className="row upper-footer-content">
                        <div className="col-lg-4 col-md-4 col-12 footer-left">
                            <div className="logo2"><a href="#"><img className="img-fluid" src="images/logo.png" alt="logo" title="logo" /></a></div>
                            <h3>Study At IIHCA</h3>
                            <ul className="study">
                                <li><a href="#">Professional Diploma Programs</a><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                <li><a href="#">Amateur Certificate Programs</a><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                <li><a href="#">Artisanal Courses</a><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                <li><a href="#">Corporate Events &amp; Support</a><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                <li><a href="#">School Culinary Program</a><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                            </ul>
                        </div>
                        <div className="col-lg-8 col-md-8 col-12 footer-right">
                            <div className="row f-right">
                                <div className="col-lg-12 col-md-12 col-12 f-address">
                                    <div className="row">
                                        <div className="col-lg-9 col-md-9 col-12">
                                            <div className="footer-address">
                                                <div className="block">
                                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                                    <p>Pilibhit Road, near Allahabad Bank, Khatima, Uttarakhand 262308</p>
                                                </div>


                                                <div className="block">
                                                    <a className="tel" href="tel:+91 9557438569"><i className="fa fa-phone" aria-hidden="true"></i>+91 9557438569</a>
                                                </div>


                                                <div className="block">
                                                    <a className="tel" href="tel:+91 9557438569"><i className="fa fa-whatsapp" aria-hidden="true"></i>+91 9557438569</a>
                                                </div>


                                                <div className="block email">
                                                    <a href="#"><i className="fa fa-clock-o" aria-hidden="true"></i>Timing: 9am -5pm</a>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-12">
                                            <ul className="footer-social">

                                                <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>


                                                <li><a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>


                                                <li><a href="#"><i className="fa fa-telegram" aria-hidden="true"></i></a></li>

                                            </ul>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-5 col-md-5 col-12 f-block">
                                    <h3>All Things IIHCA</h3>
                                    <ul className="all-thing">
                                        <li><a href="#">Professional Diploma Programs</a></li>
                                        <li><a href="#">Amateur Certificate Programs</a></li>
                                        <li><a href="#">Artisanal Courses</a></li>
                                        <li><a href="#">Corporate Events &amp; Support</a></li>
                                        <li><a href="#">School Culinary Program</a></li>
                                    </ul>
                                </div>
                                <div className="col-lg-7 col-md-7 col-12 f-map">
                                    <h3>Map</h3>
                                    <div className="map">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13970.275342398136!2d79.962274!3d28.911158!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x83eab45ac7c2ec7a!2sIshtha%20Institute%20of%20Hospitality%20%26%20Culinary%20Arts%20(IIHCA)!5e0!3m2!1sen!2sus!4v1656588107466!5m2!1sen!2sus" width="600" height="450" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer">
                    <div className="container">
                        <p className="copyright">Copyright © 2022 IIHCA</p>
                    </div>
                </div>

            </footer>
        </div>
    )
}

export default Footer