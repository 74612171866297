import React from 'react'

const AdvanceDiplomaBanner = () => {
    return (
        <div>
            <section>
                <div className="inner-bg">
                    <div className="inner-banner" style={{ backgroundImage: `url(./images/about-banner.jpg)` }}>

                    </div>
                </div>
            </section>

        </div>
    )
}

export default AdvanceDiplomaBanner