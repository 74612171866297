import React from 'react'

const Faq = () => {
    return (
        <div>
            <section>
                <div className="faq">
                    <div className="container">
                        <h2>frequently asked questions</h2>

                        <div className="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                            <div className="card">
                                {/* <!-- Card header --> */}
                                <div className="card-header" role="tab" id="heading_1"> <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapse_1" aria-expanded="false" aria-controls="collapse01">
                                    <h5 className="mb-0">Program Offered<i className="fa fa-angle-down rotate-icon" aria-hidden="true"></i></h5>
                                </a> </div>
                                {/* <!-- Card body --> */}
                                <div id="collapse_1" className="c-body collapse" role="tabpanel" aria-labelledby="heading_1" data-parent="#accordionEx" style={{}}>
                                    <div className="card-body">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                {/* <!-- Card header --> */}
                                <div className="card-header" role="tab" id="heading_2"> <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapse_2" aria-expanded="false" aria-controls="collapse01">
                                    <h5 className="mb-0">Application Requirement<i className="fa fa-angle-down rotate-icon" aria-hidden="true"></i></h5>
                                </a> </div>
                                {/* <!-- Card body --> */}
                                <div id="collapse_2" className="c-body collapse  " role="tabpanel" aria-labelledby="heading_2" data-parent="#accordionEx" style={{}}>
                                    <div className="card-body">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                {/* <!-- Card header --> */}
                                <div className="card-header" role="tab" id="heading_3"> <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapse_3" aria-expanded="false" aria-controls="collapse01">
                                    <h5 className="mb-0">Fees &amp; Payment Details<i className="fa fa-angle-down rotate-icon" aria-hidden="true"></i></h5>
                                </a> </div>
                                {/* <!-- Card body --> */}
                                <div id="collapse_3" className="c-body collapse  " role="tabpanel" aria-labelledby="heading_3" data-parent="#accordionEx" style={{}}>
                                    <div className="card-body">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Faq