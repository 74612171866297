import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Header = () => {

    const navigate = useNavigate()

    const addClass = () => {
        const headTag = document.querySelector('div.header')
        headTag.classList.toggle('bg-wite')
    }

    // const programsOffered = () => {
    //     navigate('/programsoffered')
    // }

    return (
        <div>
            {/* <!-- header--> */}
            <header>
                <div className="header">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <div className="logo"><Link to='/'><img className="img-fluid" src="images/logo.png" alt="logo" title="logo" /></Link></div>
                            <div className="logo2"><Link to='/'><img className="img-fluid" src="images/logo2.png" alt="logo" title="logo" /></Link></div>
                            <button className="navbar-toggler" onClick={addClass} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ justifyContent: "end" }}>
                                <ul className="navbar-nav navbar-right ml-6">
                                    <li className="nav-item active">
                                        <Link to='/' className="nav-link">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <div className="dropdown">
                                            <button className="nav-link btn dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                About IIHCA
                                            </button>
                                            <ul className="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
                                                <li className="dropdown-item"><Link to='/about-us'>About Us</Link></li>
                                                <li className="dropdown-item"><Link to='/why-choose-us'>Why Choose Us</Link></li>

                                            </ul>
                                        </div>

                                    </li>
                                    <li className="nav-item">
                                        <div className="dropdown">
                                            <button className="nav-link btn dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Programs Offered
                                            </button>
                                            <ul className="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
                                                <li className="dropdown-submenu">
                                                    <a className="dropdown-item" tabIndex="-1" href="#">Food Production</a>
                                                    <ul className="dropdown-menu">
                                                        <Link to='/programsoffered' className="dropdown-item"><a tabIndex="-1">Advance Diploma in Food Preparation <br />& Cookery Supervision</a></Link>
                                                        <Link to='/patisserie' className="dropdown-item"><a href="#">Patisserie</a></Link>
                                                    </ul>
                                                </li>
                                                <li className="dropdown-submenu">
                                                    <a className="dropdown-item" tabIndex="-1" href="#">Food &amp; Beverage</a>
                                                    <ul className="dropdown-menu">
                                                        <li className="dropdown-item"><Link to='/bartending' tabIndex="-1">Bar Tending</Link></li>
                                                        <li className="dropdown-item"><Link to='/barista'>Barista</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown-item"><Link to='/house-keeping'>House Keeping</Link></li>
                                            </ul>
                                        </div>

                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to='/contact-us'>contact us</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to='/blog'>blog</Link>
                                    </li>
                                    <li className="nav-item apply-btn">
                                        <a className="nav-link" href="#">Apply now</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>

                    </div>

                </div>

            </header>
        </div>
    )
}

export default Header