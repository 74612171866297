import React from 'react'
import { Link } from 'react-router-dom'

const Videos = () => {
    return (
        <div>
            <section>
                <div className="most-popular-courses">
                    <div className="container">
                        <h1>Our Most Popular Courses</h1>
                        <div className="most-popular-video-mob">
                            <video width="400" controls>
                                <source src="images/bakery.mov" type="video/mp4" />
                                    <source src="images/bakery.mov" type="video/ogg" />
                                        Your browser does not support HTML video.
                                    </video>
                                </div>

                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Food Production</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Food &amp; Beverage</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">House Keeping</a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div className="row">
                                            <div className="col-lg-4  col-md-4 col-6">
                                                <div className="most-popular-box">
                                                    <div className="image"><video width="400" controls>
                                                        <source src="images/courses-1-video.mp4" type="video/mp4" />
                                                        <source src="images/courses-1-video.mp4" type="video/ogg" />
                                                        Your browser does not support HTML video.
                                                    </video></div>
                                                    <div className="t-text">
                                                        <h3>Advance Diploma in Food Preparation & Cookery Supervision</h3>
                                                        <h4>Professional Culinary Program</h4>
                                                        <Link to='/programsoffered'>Preview this courses</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4  col-md-4 col-6">
                                                <div className="most-popular-box">
                                                    <div className="image"><video width="400" controls>
                                                        <source src="images/courses-2-video.mov" type="video/mp4" />
                                                        <source src="images/courses-2-video.mov" type="video/ogg" />
                                                        Your browser does not support HTML video.
                                                    </video></div>
                                                    <div className="t-text">
                                                        <h3>Becoming a Professional Patisserie Chef</h3>
                                                        <h4>Learn baking art, pastries, cakes & more</h4>
                                                        <Link to='/patisserie'>Preview this courses</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4  col-md-4 col-6">
                                                <div className="most-popular-box">
                                                    <div className="image"><video width="400" controls>
                                                        <source src="images/courses-3-video.mp4" type="video/mp4" />
                                                        <source src="images/courses-3-video.mov" type="video/ogg" />
                                                        Your browser does not support HTML video.
                                                    </video></div>
                                                    <div className="t-text">
                                                        <h3>Bar Tending</h3>
                                                        <h4>Raise your bar skills with our professional bartending courses</h4>
                                                        <Link to='/bartending'>Preview this courses</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab" data-aos="fade-up" data-aos-duration="1000">
                                        <div className="row">
                                            <div className="col-lg-4  col-md-4 col-6">
                                                <div className="most-popular-box">
                                                    <div className="image"><video width="400" controls>
                                                        <source src="images/courses-1-video.mp4" type="video/mp4" />
                                                        <source src="images/courses-1-video.mp4" type="video/ogg" />
                                                        Your browser does not support HTML video.
                                                    </video></div>
                                                    <div className="t-text">
                                                        <h3>lorem ipsum</h3>
                                                        <h4>Lorem Ipsum is simply dummy text</h4>
                                                        <a href="#">Preview this courses</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4  col-md-4 col-6">
                                                <div className="most-popular-box">
                                                    <div className="image"><video width="400" controls>
                                                        <source src="images/courses-2-video.mov" type="video/mp4" />
                                                        <source src="images/courses-2-video.mov" type="video/ogg" />
                                                        Your browser does not support HTML video.
                                                    </video></div>
                                                    <div className="t-text">
                                                        <h3>lorem ipsum</h3>
                                                        <h4>Lorem Ipsum is simply dummy text </h4>
                                                        <a href="#">Preview this courses</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4  col-md-4 col-6">
                                                <div className="most-popular-box">
                                                    <div className="image"><video width="400" controls>
                                                        <source src="images/courses-3-video.mp4" type="video/mp4" />
                                                        <source src="images/courses-3-video.mov" type="video/ogg" />
                                                        Your browser does not support HTML video.
                                                    </video></div>
                                                    <div className="t-text">
                                                        <h3>lorem ipsum</h3>
                                                        <h4>Lorem Ipsum is simply dummy text </h4>
                                                        <a href="#">Preview this courses</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                        <div className="row">
                                            <div className="col-lg-4  col-md-4 col-6">
                                                <div className="most-popular-box">
                                                    <div className="image"><video width="400" controls>
                                                        <source src="images/courses-1-video.mp4" type="video/mp4" />
                                                        <source src="images/courses-1-video.mp4" type="video/ogg" />
                                                        Your browser does not support HTML video.
                                                    </video></div>
                                                    <div className="t-text">
                                                        <h3>lorem ipsum</h3>
                                                        <h4>Lorem Ipsum is simply dummy text</h4>
                                                        <a href="#">Preview this courses</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4  col-md-4 col-6">
                                                <div className="most-popular-box">
                                                    <div className="image"><video width="400" controls>
                                                        <source src="images/courses-2-video.mov" type="video/mp4" />
                                                        <source src="images/courses-2-video.mov" type="video/ogg" />
                                                        Your browser does not support HTML video.
                                                    </video></div>
                                                    <div className="t-text">
                                                        <h3>lorem ipsum</h3>
                                                        <h4>Lorem Ipsum is simply dummy text </h4>
                                                        <a href="#">Preview this courses</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4  col-md-4 col-6">
                                                <div className="most-popular-box">
                                                    <div className="image"><video width="400" controls>
                                                        <source src="images/courses-3-video.mp4" type="video/mp4" />
                                                        <source src="images/courses-3-video.mov" type="video/ogg" />
                                                        Your browser does not support HTML video.
                                                    </video></div>
                                                    <div className="t-text">
                                                        <h3>lorem ipsum</h3>
                                                        <h4>Lorem Ipsum is simply dummy text </h4>
                                                        <a href="#">Preview this courses</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
            </section>
        </div>
    )
}

export default Videos