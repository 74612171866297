import React from 'react'

const Banner = () => {
    return (
        <div>
            <section>
                <div className="banner">
                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src="images/banner-1.jpg" className="d-block w-100" alt="..." />
                                <div className="carousel-caption">
                                    <div className="bg-text">
                                        <h3>GOOD FOOD GOOD LIFE</h3>
                                        <h2>Be Happy, Healthy</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                                        <div className="apply-btn"><a href="#">APPLY NOW</a></div>
                                    </div>

                                    <div className="b-img"><img src="images/human-home-2.png" /></div>

                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src="images/banner-2.jpg" className="d-block w-100" alt="..." />
                                <div className="carousel-caption">
                                    <div className="bg-text">
                                        <h3>GOOD FOOD GOOD LIFE</h3>
                                        <h2>Be Happy, Healthy</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                                        <div className="apply-btn"><a href="#">APPLY NOW</a></div>
                                    </div>

                                    <div className="b-img"><img src="images/human-home-2.png" /></div>

                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src="images/banner-3.jpg" className="d-block w-100" alt="..." />
                                <div className="carousel-caption">
                                    <div className="bg-text">
                                        <h3>GOOD FOOD GOOD LIFE</h3>
                                        <h2>Be Happy, Healthy</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                                        <div className="apply-btn"><a href="#">APPLY NOW</a></div>
                                    </div>

                                    <div className="b-img"><img src="images/human-home-2.png" /></div>

                                </div>
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Banner