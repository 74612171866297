import Slider from "react-slick"

const Testimonial = () => {

    const settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: true,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
        ]
    };

    return (
        <div>
            <section>
                <div className="testimonial">
                    <div className="container">
                        <div className="testimonial-boxes">
                            <Slider {...settings}>
                                <div className="testimonial-box">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-3">
                                            <div className="t-img">
                                                <img src="images/t-img-1.jpg" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-6">
                                            <div className="title">
                                                <h3>Praveen Joshi</h3>
                                                <p>Creative Js</p>
                                            </div>

                                        </div>
                                        <div className="col-lg-3 col-md-3 col-3">
                                            <div className="t-icon">
                                                <i className="fa fa-quote-left" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="t-text">
                                                <h4>Great quality!</h4>
                                                <p>IIHCA, I could not have done it without your motivation and support. You always say “when you come to a fork in the road, take it” well I took your advice and now I am here working at carnival cruise line as a CDP  </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-box">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-3">
                                            <div className="t-img">
                                                <img src="images/t-img-2.jpg" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-6">
                                            <div className="title">
                                                <h3>Rajendra Singh Bora</h3>
                                                <p>Creative Js</p>
                                            </div>

                                        </div>
                                        <div className="col-lg-3 col-md-3 col-3">
                                            <div className="t-icon">
                                                <i className="fa fa-quote-left" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="t-text">
                                                <h4>Great quality!</h4>
                                                <p>I am heartily grateful to IIHCA for providing me valuable education and career guidance. The faculty at the institute was very cooperative and affectionate. All of them had great command of their subject and practical knowledge.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-box">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-3">
                                            <div className="t-img">
                                                <img src="images/t-img-3.jpg" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-6">
                                            <div className="title">
                                                <h3>Gopal Singh Bisht</h3>
                                                <p>Creative Js</p>
                                            </div>

                                        </div>
                                        <div className="col-lg-3 col-md-3 col-3">
                                            <div className="t-icon">
                                                <i className="fa fa-quote-left" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="t-text">
                                                <h4>Great quality!</h4>
                                                <p>IIHCA has proved to be milestone in my life. I appreciate all the knowledge and experience that I gained during the period of one year I spent here. I have all praise for faculty and HOD for their excellent guidance in shaping my career.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-box">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-3">
                                            <div className="t-img">
                                                <img src="images/t-img-2.jpg" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-6">
                                            <div className="title">
                                                <h3>Praveen Joshi</h3>
                                                <p>Creative Js</p>
                                            </div>

                                        </div>
                                        <div className="col-lg-3 col-md-3 col-3">
                                            <div className="t-icon">
                                                <i className="fa fa-quote-left" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="t-text">
                                                <h4>Great quality!</h4>
                                                <p>IIHCA, I could not have done it without your motivation and support. You always say “when you come to a fork in the road, take it” well I took your advice and now I am here working at carnival cruise line as a CDP  </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}

export default Testimonial