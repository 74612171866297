import React from 'react'

const About = () => {
    return (
        <div>
            <section>
                <div className="about-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="about-text">
                                    <h2>Ishtha Institute Of Hospitality &amp; Culinary Arts</h2>
                                    <p>ISHTHA Institute of Hospitality &amp; Culinary Arts is a prestigious culinary institute that is stationed in India and has an unblemished legacy of 8 years of producing professionals and placing them in top-tier positions. The exemplary training provided at IIHCA arms the students for future challenges and makes them competent to perform exceptionally well in the fast-paced environment of the hospitality industry. The team members and mentors inspire the students to build their skillsets and grow in their respective fields of interest. These well-trained students have attained stellar positions in the field of hospitality and culinary kitchen across the globe.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="about-image">
                                    <img src="images/about-us.png" />
                                    <img src="images/about-us-1.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About