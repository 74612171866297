import React from 'react'
import Slider from 'react-slick';

const Alumni = () => {

    const settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: true,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
        ]
    };

    return (
        <div>
            <section>
                <div className="alumni">
                    <div className="container">
                        <h2>Meet our alumni</h2>

                        <div className="alumni-boxes">
                            <Slider {...settings}>
                                <div className="alumni-box">
                                    <div className="a-box-content">
                                        <div className="alumni-img">
                                            <img src="images/t-img-1.jpg" />
                                        </div>
                                        <h4>Praveen Joshi</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry </p>
                                    </div>
                                    <div className="view"><a href="#">View profile<i className="fa fa-instagram" aria-hidden="true"></i></a></div>
                                </div>
                                <div className="alumni-box">
                                    <div className="a-box-content">
                                        <div className="alumni-img">
                                            <img src="images/t-img-1.jpg" />
                                        </div>
                                        <h4>Praveen Joshi</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry </p>
                                    </div>
                                    <div className="view"><a href="#">View profile<i className="fa fa-instagram" aria-hidden="true"></i></a></div>
                                </div>
                                <div className="alumni-box">
                                    <div className="a-box-content">
                                        <div className="alumni-img">
                                            <img src="images/t-img-1.jpg" />
                                        </div>
                                        <h4>Praveen Joshi</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry </p>
                                    </div>
                                    <div className="view"><a href="#">View profile<i className="fa fa-instagram" aria-hidden="true"></i></a></div>
                                </div>
                                <div className="alumni-box">
                                    <div className="a-box-content">
                                        <div className="alumni-img">
                                            <img src="images/t-img-1.jpg" />
                                        </div>
                                        <h4>Praveen Joshi</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry </p>
                                    </div>
                                    <div className="view"><a href="#">View profile<i className="fa fa-instagram" aria-hidden="true"></i></a></div>
                                </div>
                                <div className="alumni-box">
                                    <div className="a-box-content">
                                        <div className="alumni-img">
                                            <img src="images/t-img-1.jpg" />
                                        </div>
                                        <h4>Praveen Joshi</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry </p>
                                    </div>
                                    <div className="view"><a href="#">View profile<i className="fa fa-instagram" aria-hidden="true"></i></a></div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Alumni